import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { withPrefix } from "gatsby"
import Layout from "../components/layout"
const TermsAndConditions = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/terms-and-conditions/",
        name: "Terms And Conditions - Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/terms-and-conditions/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Document eSign created customized Privacy Policies and Terms &amp; Conditions in a matter of seconds to keep your business safe from any legal issue.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/terms-and-conditions/"],
          },
        ],
      },
    ],
  }
  return (
    <>
      <Layout rawData={{ className: "sticky-header" }}>
        <SEO
          title="Terms And Conditions - Document eSign"
          description="Document eSign created customized Privacy Policies and Terms &amp; Conditions in a matter of seconds to keep your business safe from any legal issue."
          schemaMarkup={schema}
        />
        <div className="intro contact-intro bg-blue-light ani-in show-on-scroll">
          <div className="container text-center">
            <h1 className="text-center pt-5 mt-5">Terms &amp; Conditions</h1>
          </div>
        </div>
        <div className="bg-lights py-5">
          <div className="container">
            <p>
              These are the terms of use of Document eSign with office address
              at 1st Floor, Silver Spring, Sahyadri Farms, Balewadi Road, Behind
              Kapil Complex, Baner, Pune, MH, India 411045.
            </p>

            <p>
              These terms can be found at the website of Document eSign{" "}
              <a href="https://documentesign.com/">documenteisgn.com</a> (the
              Website) and will be sent on request by email or by post. If you
              have any questions about these terms you can send an email to{" "}
              <a href="mailto:contact@documentesign.com">
                contact@documentesign.com
              </a>{" "}
              or call at +91 9834625788.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Applicability of Terms of Use</strong>
              </strong>
            </h3>

            <p>
              <strong>1.1)</strong> These terms of use apply to all aspects of
              the legal relationship between Document eSign and the users
              (hereinafter a <strong>User</strong>) of the services provided by
              Document eSign (the <strong>Services</strong>).
            </p>

            <p>
              <strong>1.2)</strong> Document eSign reserves the right to alter
              or amend these terms of use from time to time. Document eSign will
              make reasonable efforts to inform the User of such changes through
              Document eSign’s website. If a User subsequently continues to use
              the Services, this will be understood to imply User&#8217;s
              acceptance of the alterations and amendments.
            </p>

            <p>
              <strong>1.3)</strong> If any part of these terms of use turns out
              to be void or unenforceable, the remainder for the terms of use
              remains valid and the void or unenforceable provision will be
              considered to have been replaced with a provision that corresponds
              as much as possible with the meaning and purpose of the void or
              unenforceable provision.
            </p>

            <p>
              <strong>1.4)</strong> These Terms and Conditions are subject to
              change at any time. Use of this site forms your consent and
              legally binding agreement to adhere to these terms. Document eSign
              may occasionally contact you via email.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Your Data and Privacy</strong>
              </strong>
            </h3>

            <p>
              <strong>2.1)</strong> When you use Document eSign, you agree that
              Document eSign may duplicate and store your data, information,
              files, and folders in accordance with Document eSign policies and
              these Terms. All information you provide to Document eSign will
              remain your property. When you input or upload any file or
              document into Document eSign, regardless of its form or the
              content therein, you grant Document eSign a license to use that
              document as necessary to provide the services listed on the site.
              Any electronic information or recording created on or through
              Document eSign is and will remain the property of Document eSign.
            </p>

            <p>
              <strong>2.2)</strong> For the purpose of providing the service
              only, we may need to share your data, information, folders, and
              files with third parties and your use of this service forms your
              consent for the same. Document eSign may contain links to
              third-party websites or resources. Your use of the third-party
              materials is governed by their terms and not this agreement and
              responsibility for their service relies solely on them. You are
              responsible for maintaining and protecting your data in Document
              eSign and Document eSign is not responsible for any corruption or
              loss of your files or information or for backing up such
              information.
            </p>

            <p>
              <strong>2.3)</strong> By using the Services, you consent to
              receive electronic communications from the Company and its
              partners and affiliates. You may also send an electronic
              communication to the Company as specified in the Agreement. These
              electronic communications may include without limitation notices
              about your Subscription Services, your invoices or payments,
              changes to the fees or the Agreement, reports of security
              violations, your violations of the Agreement, suspension of your
              use of the Services, termination of the Agreement, changes to
              Services, availability of new products and services, or other
              information relating to Company, Services or third-party partners.
            </p>

            <p>
              You must be 18 or over to use Document eSign and acceptance of
              terms constitutes your confirmation of the same.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Acceptable Use and Conduct</strong>
              </strong>
            </h3>

            <p>
              <strong>3.1)</strong> You are solely responsible for your conduct
              and your data content uploaded or otherwise placed into&nbsp;
              Document eSign.&nbsp; Document eSign does not hold responsibility
              for any lack of appropriateness of any information which you may
              be able to access or view through&nbsp; Document eSign. You agree
              to indemnify, defend, and hold harmless&nbsp; Document eSign and
              its suppliers from any and all loss, cost, liability, and expense
              arising from or related to your data, your use of the Product, or
              your violation of these terms.
            </p>

            <p>
              <strong>3.2)</strong>&nbsp; Document eSign is for your personal or
              internal business use only and must be in compliance with all
              applicable&nbsp; Document eSign policies, laws, rules and
              regulations. As well as this, no third party rights can be
              violated or infringed upon through use of&nbsp; Document eSign.
              You may not use&nbsp; Document eSign for any commercial purpose
              including but not limited to selling, licensing, providing
              services, or distributing&nbsp; Document eSign to any third party
              unless you have received the express written consent of&nbsp;
              Document eSign beforehand.
            </p>

            <p>
              <strong>3.3)</strong> Any unauthorized use of any&nbsp; Document
              eSign computer system will be considered a violation of these
              Terms and may also violate certain national laws. Such violations
              may subject the unauthorized user and his or her agents to civil
              and criminal penalties.&nbsp; Document eSign may immediately
              remove any content or data, or suspend or cancel accounts if it
              becomes aware of any misuse or illegal actions associated with an
              account or user, without prior notice to You.
            </p>

            <p>
              <strong>3.4)</strong> While using&nbsp; Document eSign you are
              prohibited from abusing the rights and privacy of any other user.
              In addition, you are not allowed to copy or upload files, run any
              vulnerability or authentication tests. You are strictly forbidden
              from sending any viruses, spam or malware, or disrupting the site
              or flow of information in any way. You must not use any offensive
              or unlawful material or publish anything misleading or fraudulent
              in nature.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Passwords and Security</strong>
              </strong>
            </h3>

            <p>
              <strong>4)</strong> You must be a registered user to access
              Document eSign. Users must register using their email address,
              which becomes automatically their username. If you are a
              Registered User, you are responsible for safeguarding your
              password that you use to access Services and you agree not to
              disclose it to any third party. If you suspect your password has
              been compromised, you need to promptly change it. You will notify
              Company immediately of any unauthorized use of your Company
              account. You hereby take responsibility for all actions taken
              under your account by you or any third parties including any
              abuse, unauthorized use, and resulting fees.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>
                  Changes to&nbsp; Document eSign Products and Terms
                </strong>
              </strong>
            </h3>

            <p>
              <strong>5.1)</strong> Document eSign reserves the right to change
              any part of its service without prior notice and at any time
              without liability to you. This Agreement, as amended, will be
              effective upon use of the&nbsp; Document eSign for all existing
              users immediately after posting of any amended terms on the&nbsp;
              Document eSign website. You agree to be bound by this Agreement,
              as modified. Any break to these clauses will result in your
              account being terminated.
            </p>

            <p>
              <strong>5.2)</strong> You are advised to review the most current
              version of this Agreement from time to time, located at{" "}
              <a href="https://documentesign.com/terms-and-conditions/">
                https://documentesign.com/terms-and-conditions/
              </a>
              &nbsp; (or such successor URL as&nbsp; Document eSign may
              provide), so that you will be apprised of any changes.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Proprietary Information</strong>
              </strong>
            </h3>

            <p>
              <strong>6.1)</strong> The Services (including their look and feel)
              contain copyrighted material, trade secrets and other confidential
              material of the Company and its licensors. Company and its
              licensors own and will retain ownership of all rights, title, and
              interest in Services including intellectual property rights
              therein (excluding your Content). You own and will retain
              ownership of all right, title, and interest in your Content. All
              rights not expressly granted herein are reserved. Except as
              otherwise required by applicable law, any reproduction,
              distribution, modification, retransmission, or publication of any
              copyrighted material is strictly prohibited without the express
              written consent of the copyright owner.&nbsp;
            </p>

            <p>
              <strong>6.2)</strong> Neither party will do anything inconsistent
              with such title including, but not limited to, transferring,
              loaning, selling, assigning, pledging, or otherwise disposing,
              encumbering, or suffering a lien or encumbrance upon or against
              any interest in the other party&#8217;s rights.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Limitation on Excessive Usage</strong>
              </strong>
            </h3>

            <p>
              <strong>7)</strong> If your use of the Services significantly
              exceeds that of the average user (in the Company’s sole opinion),
              then the Company may throttle or temporarily disable your use of
              the Services. If your use is excessive to the point that it could
              damage, disable, overburden, or impair Services or interfere with
              any other party&#8217;s use and enjoyment of the Services, then
              the Company may immediately disable your use of the Services. For
              the purposes herein, unless you specifically purchased a higher
              usage level, excessive usage means: (a) bandwidth in excess of
              300MB/month; (b) envelopes, faxes, or form fills in excess of 100
              per Subscriber per month; (c) file sizes in excess of 25 MB, or
              (d) downloading more than 20 forms per month from US Legal premium
              forms library.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Usage of devices and Internet</strong>
              </strong>
            </h3>

            <p>
              <strong>8)</strong> Services depend on third party network and
              Internet providers and device manufacturers that are outside of
              the Company&#8217;s control. You acknowledge that the Company will
              not be responsible or liable for performance or non-performance as
              a result of such networks or devices. You understand that the
              processing and transmission of the Services, including Your
              Content, may involve transmissions over various networks and
              unencrypted transfer to a network or device. You understand that
              the third-party networks or devices may change their technical
              requirements interfering with the operation of the Services.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                <strong>Electronic signatures and signed documents</strong>
              </strong>
            </h3>

            <p>
              <strong>9.1)</strong> Document eSign Services enable the use of
              signatures in electronic form. The legal status of such signatures
              can vary depending on the applicable jurisdiction, the purpose for
              which the signatures are used, and other circumstances that may be
              relevant under applicable law. It is the User&#8217;s
              responsibility to ensure that signatures obtained or provided by
              means of the Services have the desired legal effect.&nbsp;
              Document eSign makes no commitments in this respect.
            </p>

            <p>
              <strong>9.2)</strong> Document eSign will take reasonable measures
              to safeguard the confidentiality of documents and information
              exchanged by means of the Services.&nbsp; Document eSign makes no
              commitments with regard to the availability of such documents and
              information or any information generated in the context of the
              Free Services (such as, without limitation, signing logs).
            </p>

            <p>
              <strong>9.3)</strong> For Free Services,&nbsp; Document eSign
              reserves the right to remove documents or information from its
              systems at any time. For registered Accounts or Paid Services
              documents and information will remain stored for the User’s
              access.&nbsp; Document eSign reserves the right to remove
              documents or information if an Account is not used for more than
              12 months but only after having timely notified an affected User.
            </p>

            <p>
              <strong>9.4)</strong> Document eSign makes no commitments with
              regard to the successful completion of a signing process, such as
              (without limitation) with regard to emails reaching the intended
              recipient or the availability of the Services for the completion
              of a signing process once initiated.
            </p>

            <p>
              <strong>9.5)</strong> For the avoidance of doubt,&nbsp; Document
              eSign has no involvement with and accepts no responsibility:
            </p>

            <ul>
              <li>
                for the content of documents or other information exchanged
                through and/or signed by means of Services; or
              </li>
              <li>
                whether or not any documents are legally valid and binding under
                any jurisdiction.&nbsp; Document eSign does not monitor such
                content or other information exchanged through the Services.
              </li>
            </ul>

            <h3 className="mb-4 mt-3">
              <strong>License to&nbsp; Document eSign</strong>
            </h3>

            <p>
              <strong>10)</strong> Subject to the terms and conditions of this
              Agreement,&nbsp; Document eSign grants you a non-exclusive, non-
              transferable, limited, and revocable license to use the Product
              for the sole and exclusive purposes of your personal or internal
              business purposes. The Product and their structure, organization,
              source code, and documentation belong solely to&nbsp; Document
              eSign and its licensors, and accordingly, you agree not to allow
              third parties to sublicense, transfer, or distribute any part of
              the&nbsp; Document eSign service to any third party. You are not
              allowed to modify, adapt, or translate any part of this service,
              or in any way, act to derive source code from the Product.{" "}
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Renewals</strong>
            </h3>

            <p>
              <strong>11)</strong> You agree that&nbsp; Document eSign retains
              the right to automatically and without notice renew your license
              to continue to use the Product upon expiration of your license
              period. You agree that if you elect to not permit&nbsp; Document
              eSign the right to automatically renew your license to use the
              Product or maintain your credit card information on file,
              then&nbsp; Document eSign may terminate your license.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Intellectual Property &amp; Copyright</strong>
            </h3>

            <p>
              <strong>12.1)</strong> You acknowledge that&nbsp; Document eSign
              or third parties own all right, title and interest in and to the
              computer source code related to the Product, portions thereof, or
              software or content provided through or in conjunction with the
              Product, including without limitation all intellectual property
              rights. Except for the license granted in this section, all rights
              in and to the Product are reserved, and no implied licenses are
              granted by&nbsp; Document eSign.
            </p>

            <p>
              <strong>12.2)</strong> If you have comments on the Product or
              ideas on how to improve them, please email
              <a href="mailto:contact@dodumentesign.com">
                contact@documentesign.com
              </a>
              . Please note that by doing so, you also grant&nbsp; Document
              eSign a perpetual, fully paid, royalty-free, irrevocable,
              transferable license, with right of sublicense, to use and
              incorporate your ideas or comments into the Product (or third
              party software, content, or services), and to otherwise exploit
              your ideas and comments, in each case without further
              compensation. When using&nbsp; Document eSign, you must respect
              the intellectual property rights of others. We will respond to any
              notice of alleged copyright infringement if they comply with the
              law and are properly reported to us. We reserve the right to
              delete or disable content alleged to infringe.
            </p>

            <p>
              <strong>12.3)</strong> Any feedback or suggestions provided by the
              User with regard to the use, design, or functionally of the
              Services may be used freely by&nbsp; Document eSign for the
              improvement and further development thereof. Any new intellectual
              property resulting from such improvement or development will
              remain with&nbsp; Document eSign. To the extent that such new
              intellectual property does not reside with&nbsp; Document eSign by
              operation of law, User will render all cooperation necessary to
              affect the transfer of such intellectual property to&nbsp;
              Document eSign.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Pricing Terms and Conditions</strong>
            </h3>

            <p>
              <strong>13.1)</strong> Free Accounts: Document eSign offers a free
              account option.&nbsp; Document eSign reserves the right to change
              the free account structure or terminate free accounts at any time.
            </p>

            <p>
              <strong>13.2)</strong> Billing: Premium fees for upgraded accounts
              (Pro Accounts) will be billed from the date you elect, or convert
              to, a Pro Account and on each renewal thereafter unless and until
              you cancel your Pro Account.&nbsp; Document eSign will
              automatically bill you by credit card or otherwise if agreed upon
              each period on the anniversary of the commencement of your Pro
              Account. You acknowledge that the amount billed in any given
              period may vary for reasons that include, differing amounts due to
              promotional offers, differing amounts due to changes in your
              account, or changes in the amount of applicable sales tax, and you
              authorize us to charge you for such varying amounts. All fees and
              charges are non-cancelable and non-refundable, and no credits will
              be given for partially used periods, unless otherwise agreed to.
            </p>

            <p>
              <strong>13.2.1)</strong> If any fee is not paid in a timely
              manner, or&nbsp; Document eSign is unable to process your
              transaction using the information provided,&nbsp; Document eSign
              reserves the right to revoke access to your&nbsp; Document eSign
              account.&nbsp; Document eSign reserves the right to use our
              discretion to delete some or all of your data in order to prevent
              incurring continued costs after 30 days of delinquency.
            </p>

            <p>
              &nbsp;<strong>13.2.2)</strong> Document eSign may change the fees
              and charges in effect, or add new fees and charges from time to
              time, but we will give you advance notice of these changes by
              email or on the website. If you want to use a different payment
              method, you may edit your information by contacting a support
              representative. It is your responsibility to keep your contact
              information and payment information current and updated.
            </p>

            <p>
              <strong>13.3)</strong> Cancelling Your Pro Account: Your Pro
              Account will continue in effect unless and until you cancel your
              Pro Account or we terminate it. You must cancel your Pro Account
              before it renews each month or year in order to avoid billing of
              the next month’s or year’s fees to your credit card.&nbsp;
              Document eSign will bill the monthly or yearly fees associated
              with your Pro Account plus any applicable tax to the credit card
              you provide to us. You may cancel your Pro Account at any time,
              and cancellation will be effective immediately. If you wish to
              cancel your Pro Account you may do so by talking to a
              representative. Refunds will not be issued on monthly or annual
              fees due to cancellation.
            </p>

            <p>
              By signing up for a&nbsp; Document eSign Pro Account and
              providing&nbsp; Document eSign with your payment account
              information, you hereby agree to these payment terms and
              conditions.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Terminating Your Account</strong>
            </h3>

            <p>
              <strong>14.1)</strong> This Agreement is effective upon any use
              and remains in effect until your account is terminated. Terms that
              by their nature extend beyond termination shall survive
              termination of this Agreement. Failure to comply with any terms
              herein will result in automatic account termination. You agree to
              stop using all services upon termination of account.&nbsp;
              Document eSign reserves the right to refuse or discontinue
              participation to any user at any time at its sole discretion. Upon
              termination of agreement&nbsp; Document eSign will allow access to
              your data for a period of 15 days after termination but reserves
              and notes that it is not legally obliged to do so.
            </p>

            <p>
              If you are a Visitor or Registered User of Free Services, the
              Agreement will remain in full force and effect while you use the
              Services, except that Company or you may terminate this Agreement
              at any time for convenience without liability or notice.
            </p>

            <p>
              If you are a Subscriber or Customer, the Agreement will be
              effective as of the earlier of either (i) the date that you accept
              this click-thru Agreement or (ii) the date that you signed a sales
              order.&nbsp; If you are a Customer, the Agreement will terminate
              upon the earlier of the completion of the one-time transaction or
              thirty (30) days from the effective date.&nbsp; If you are a
              Subscriber, your initial Subscription Period will expire at the
              end of the Subscription Period specified in your applicable order
              form, or, if no term is specified, until the Agreement is
              terminated by either party. This Agreement will, upon the
              expiration of your initial Subscription Period, automatically
              renew for successive Subscription Periods equal in duration to
              your initial term, or, if no term is specified, the Agreement term
              will renew on a month-to-month basis unless either party notifies
              the other prior to the end of the then current Subscription Period
              that it has elected not to renew the Services. If you are an
              individual Subscriber or an administrator for the account, you may
              notify the Company of such non-renewal by (a) logging into the
              Services and cancelling your account through your account settings
              (if available) or (b) contacting Company support and receiving
              written confirmation by Company of your request. If you or the
              Company elects not to renew the Services before the end of your
              current Subscription Period, you will not be charged for
              Subsequent Periods.
            </p>

            <p>
              You may terminate the Agreement and your Subscription to the
              Services if the Company materially breaches or otherwise fails to
              comply with this Agreement and has not cured such breach within
              fifteen (15) days of Company’s receipt of written notice from you
              specifying the alleged breach.
            </p>

            <p>
              <strong>14.2)</strong> Upon termination, (a) Company will no
              longer be obligated to provide you the Services, (b) your account
              and Your Content, Company Content or Third Party Content contained
              therein will no longer be accessible by you, (c) you will
              immediately stop using Services, and (c) all licenses and other
              rights granted to you under the Agreement will immediately cease.
              Company will not be liable to you or any third party for
              termination of this Agreement or any termination or suspension of
              your use of the Services. If you are a Subscriber or Customer,
              termination by the Company will not result in any refund of fees
              for the current Subscription Period and you are still obligated to
              pay any outstanding, unpaid fees.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>User accounts and User responsibilities</strong>
            </h3>

            <p>
              <strong>15.1)</strong> Document eSign offers User the possibility
              to create a user account for the purpose of simplifying repeated
              use of Services by, inter alia, enabling storage of certain User
              details and for access to Paid Services. It is User&#8217;s
              responsibility to ensure safekeeping of account passwords and
              other login details, and more in general to protect his account
              against unauthorized use.
            </p>

            <p>
              <strong>15.2)</strong> Users must ensure that the Services are
              used in conformity with&nbsp; Document eSign’s instructions and
              for the purpose that&nbsp; Document eSign intended them for. More
              specifically (without limitation), User is not allowed to use
              Services:
            </p>

            <ul>
              <li>
                in any way that affects the integrity or continuity of&nbsp;
                Document eSign’s systems;
              </li>
              <li>
                to integrate Services with internal business systems or with
                systems or services provided by User or any third party, or in
                any other way that does not involve normal use of the manual
                user interface provided by&nbsp; Document eSign; or
              </li>
              <li>to resell or redistribute Services in any way.</li>
            </ul>

            <p>
              <strong>15.3)</strong> It is the User&#8217;s responsibility to
              use the Services in compliance with applicable law and with any
              third party rights. More specifically (without limitation), User
              is not allowed to use the Services:
            </p>

            <ul>
              <li>
                in a way that infringes on a third party&#8217;s intellectual
                property rights;
              </li>
              <li>in a way that violates a person&#8217;s privacy;</li>
              <li>
                to send unsolicited messages or Document eSign to any third
                party recipient.
              </li>
            </ul>

            <p>
              <strong>15.4)</strong>&nbsp; Document eSign reserves the right to
              block or terminate an Account at any time and delete all
              information related to it.
            </p>

            <p>
              <strong>15.5)</strong> User and Clients indemnify&nbsp; Document
              eSign for any third party claims that may arise from any use of
              the Services that is in violation of applicable law or unlawful in
              any other way.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Liabilities</strong>
            </h3>

            <p>
              <strong>16.1)</strong> except for your breach of any of your
              obligations in section 4 above, in no event will either party be
              liable for any indirect, incidental, special, consequential,
              punitive or exemplary damages of any kind, including, without
              limitation, lost revenues, profits or goodwill lost data or
              content, data breaches, lost customers, business interruption or
              replacement services, in connection with the services or from your
              use of or inability to use services however caused and regardless
              of theory of liability, whether or not such party knew or had
              reason to know of the possibility of such damages and whether or
              not the remedies provided for herein fail of their essential
              purpose.&nbsp;
            </p>

            <p>
              the total cumulative liability of the company to you for any and
              all claims and damages under this agreement, whether arising by
              statute, contract, tort or otherwise, will not exceed the amount
              of fees paid by you to company during the 6-month period before
              the date on which any claim arose.
            </p>

            <p>
              More specifically (without limitation),&nbsp; Document eSign
              accepts no liability, to the extent permitted by applicable law,
              for loss of data, third party access to data due to unlawful entry
              into computer systems, a signing process not being completed, a
              signature not having the desired legal effect, loss of contract,
              loss of contract value or loss of income or profits or any (other)
              indirect or consequential damages irrespective whether the
              Services are offered by&nbsp; Document eSign directly or through a
              third party.
            </p>

            <p>
              <strong>16.2) </strong>For Paid Services,&nbsp; Document eSign
              accepts liability for damages caused directly by the Paid Services
              to an individual User. This liability is limited to the invoiced
              amount with a maximum of 12 cumulative months.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>GENERAL PROVISIONS</strong>
            </h3>

            <p>
              <strong>17.1)</strong> The Agreement does not establish the
              parties as business partners or agents of the other, and neither
              party has the right to bind the other on any third-party
              agreement.
            </p>

            <p>
              <strong>17.2) </strong>Each party may enforce each of its
              respective rights under the Agreement even if the party has waived
              the right or delayed or failed to enforce the same or other rights
              in the past. All waivers must be in writing and signed by the
              party waiving its rights.
            </p>

            <p>
              <strong>17.3)</strong> If any part of the Agreement is found
              unenforceable by a court of competent jurisdiction, the rest of
              the Agreement will nonetheless continue in effect, and both
              parties agree that the unenforceable provisions will be modified
              so as to best accomplish the objectives of the Agreement within
              the limits of applicable law.
            </p>

            <p>
              <strong>17.4) </strong>To the extent permitted by applicable law,
              both parties rights and remedies provided herein are cumulative
              and in addition to any other rights and remedies at law or equity.
            </p>

            <p>
              <strong>17.5) </strong>The captions in the Agreement are for
              convenience only and are not part of the Agreement. The use of the
              word &#8220;including&#8221; in the Agreement shall be read to
              mean &#8220;including without limitation.&#8221;
            </p>

            <p>
              <strong>17.6)</strong> Neither party may assign any of its rights
              or obligations hereunder, except in connection with a merger or
              acquisition. The Agreement shall be binding upon, and inure to the
              benefit of, the successors and assigns of the parties thereto.
            </p>

            <p>
              <strong>17.7)</strong> Both parties agree that any notices,
              agreements, disclosures or other communications that the other
              party sends to it electronically will satisfy any legal
              communication requirements, including that such communications be
              in writing, provided that any communication to you is sent to the
              email address provided on your account and that any communication
              to Company is send to the applicable email address specified in
              the Ag
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Disclaimer of Warranties</strong>
            </h3>

            <p>
              <strong>18.1)</strong> THE PRODUCTS AND ANY THIRD PARTY SOFTWARE
              AND SERVICES ARE PROVIDED “AS IS,” WITH NO WARRANTIES WHATSOEVER.
              Document eSign AND SUCH THIRD PARTIES EXPRESSLY DISCLAIM TO THE
              FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS, IMPLIED, AND
              STATUTORY WARRANTIES, INCLUDING, WITHOUT LIMITATION, THE
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS AND ANY
              WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND
              PERFORMANCE OF THE PRODUCTS, SOFTWARE AND SERVICE AND SUCH
              THIRD-PARTY SOFTWARE OR SERVICES.
            </p>

            <p>
              <strong>18.2)</strong> YOU UNDERSTAND AND AGREE THAT YOU USE THE
              PRODUCT, AND ALL THIRD PARTY SOFTWARE OR SERVICES MADE AVAILABLE
              IN CONJUNCTION WITH OR THROUGH THE PRODUCT, AT YOUR OWN DISCRETION
              AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGES
              TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE USE
              OF THE PRODUCT AND SUCH THIRD PARTY SOFTWARE AND SERVICES.
            </p>

            <p>
              SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED
              WARRANTIES, AND WHERE THAT IS IN EFFECT, THE ABOVE EXCLUSIONS MAY
              NOT APPLY TO YOU.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Governing Law, Dispute Resolution and Venue</strong>
            </h3>

            <p>
              <strong>19.1)</strong> The terms and use of Document eSign ,
              product and software, are governed by Indian German law. Any
              litigation arising from use of Document eSign will be done through
              Indian courts. As with all terms and conditions, use of Document
              eSign constitutes agreement to the same.
            </p>

            <p>
              <strong>19.2)</strong> If a provision is found unenforceable the
              remaining provisions of the Agreement will remain in full effect
              and an enforceable term will be substituted which as closely as
              possible reflects Document eSign intent.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Entire Agreement and Assignment</strong>
            </h3>

            <p className="mb-5">
              <strong>20) </strong>These terms and conditions include Document
              eSign privacy policy and constitutes the entire agreement between
              all parties respecting the subject matter herein and supersedes
              and replaces all prior or contemporaneous understandings or
              agreements, written or oral, regarding Document eSign. Document
              eSign’s failure to enforce a provision is not a waiver of its
              right to do so later. Any waiver of any provision of this
              Agreement will be effective only if in writing and signed by
              Document eSign. No rights may be transferred or assigned under
              this Agreement by you but you acknowledge and agree that Document
              eSign may freely assign its rights and obligations and this
              Agreement. Any attempted assignment or transfer in violation of
              the foregoing will be null and void.
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default TermsAndConditions
